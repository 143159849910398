import { Component, OnInit } from '@angular/core';
import { EcleticoQuadrinhoService } from "src/app/service/ecletico-quadrinho.service";

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.css']
})
export class ListaComponent implements OnInit {
  nameValue: string;
  placeValue: string;
   botao:boolean= true;
  //Data object for listing items
  blogs: any[] = [];

  constructor(
    private EQS: EcleticoQuadrinhoService,
  ) {
  }
 
  async ngOnInit() {
    await    this.loadItems();

  }
 
 
  async loadItems() {
    for (var i=0; i < 5; i++) {
      this.blogs.push({
        blog: this.EQS.blogs[i].blog,
        id: this.EQS.blogs[i].id,
      });
    }
  }
 

}
