import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quadrinhos',
  templateUrl: './quadrinhos.component.html',
  styleUrls: ['./quadrinhos.component.css']
})
export class QuadrinhosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
