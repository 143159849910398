import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AngularFirestoreDocument,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { EcleticoQuadrinhoService } from "./service/ecletico-quadrinho.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  carregou: boolean;
  router: string;
  nameValue: string;
  placeValue: string;
  cacheVeri: any;
  variavel: number = 0;
  tela: any;
  veri: boolean = false;

  constructor(
    public _router: Router,
    private firestore: AngularFirestore,
    private EQS: EcleticoQuadrinhoService
  ) {}

  async ngOnInit() {
    this.firestore
      .doc("configuracao/cache")
      .valueChanges()
      .subscribe((res) => {
        this.cacheVeri = res;
        this.EQS.tudo(res);
        console.log(res);
        this.EQS.blog(res);
        this.EQS.quadrinho(res);
        this.EQS.seriado(res);
        this.carregou=true;
      },
      (error) => {});
    this.loadItems();
    console.log(this.carregou);
  }

  loadItems() {
    this.firestore
      .collection("tela", (ref) => ref.limit(1))
      .snapshotChanges()
      .subscribe(
        (response) => {
          if (!response.length) {
            console.log("No Data Available");
            return false;
          }

          this.tela = response[0].payload.doc.data();

          this.veri = true;
        },
        (error) => {}
      );
  }
}
