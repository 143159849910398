import { Component, OnInit } from "@angular/core";
import { EcleticoQuadrinhoService } from "src/app/service/ecletico-quadrinho.service";

@Component({
  selector: "app-lista-todos",
  templateUrl: "./lista-todos.component.html",
  styleUrls: ["./lista-todos.component.css"],
})
export class ListaTodosComponent implements OnInit {
  nameValue: string;
  placeValue: string;
  botao: boolean = true;
  //Data object for listing items
  quadrinhos: any[] = [];

  constructor(
    private EQS: EcleticoQuadrinhoService,
  ) {
    
  }

  async ngOnInit() {
    await this.loadItems();
  }

  async loadItems() {
    for (var i=0; i < 5; i++) {
      this.quadrinhos.push({
        quadrinho: this.EQS.responseQuadrinho[i].quadrinho,
        id: this.EQS.responseQuadrinho[i].id,
      });
    }

  }
}
