import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { quadrinhoDoc } from 'src/app/service/quadrinhoDoc';
import { EMPTY, Observable } from 'rxjs';
import { Quadrinho } from 'src/app/service/quadrinho';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';


@Component({
  selector: 'app-ecleticos-detalhe',
  templateUrl: './ecleticos-detalhe.component.html',
  styleUrls: ['./ecleticos-detalhe.component.css']
})
export class EcleticosDetalheComponent {
  id: string;
  num: string;
  options: any = {
    title: 0,
    toolbar: {
      zoomIn: 1,
      zoomOut: 1,
      oneToOne: 0,
      fullscreen: 0,
      reset: 0,
      prev: 1,
      play: 0,
      next: 1,
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    }
  };
  authResult: any;
  usuario: any;
  favorito: { url: any; id: string; tipo: string, nome: string };
  quadrinhoObservable: Observable<Quadrinho>;
  quadrinho;
  constructor(public quadrinhoDoc: quadrinhoDoc, private route: ActivatedRoute,
    private router: Router,
    private EQS: EcleticoQuadrinhoService
  ) {

  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.EQS.quadrinhoAtualId != undefined && this.EQS.quadrinhoAtualId==this.id) {
      this.quadrinho = this.EQS.quadrinhoAtual;
    }  else {
      this.quadrinhoObservable = this.quadrinhoDoc.init(this.id);
      this.quadrinhoObservable.subscribe(result => {
        this.quadrinho = result;
      })
    }

  }

  

}
