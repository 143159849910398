import { Component } from '@angular/core';
import { blogDoc } from 'src/app/service/blogDoc';
import { ActivatedRoute } from '@angular/router';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';
import { Observable } from 'rxjs';
import { Blog } from 'src/app/service/blog';


@Component({
  selector: 'app-blog-detalhe',
  templateUrl: './blog-detalhe.component.html',
  styleUrls: ['./blog-detalhe.component.css']
})
export class BlogDetalheComponent {
  id: string;
  blogObservable: Observable<Blog>;
  blog;
  constructor(public blogDoc: blogDoc, private route: ActivatedRoute,
    private EQS: EcleticoQuadrinhoService) {

  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.EQS.blogIdAtual != undefined && this.EQS.blogIdAtual == this.id) {
      this.blog = this.EQS.blogAtual;
      console.log("primeiro")
    } else {
      this.blogObservable = this.blogDoc.init(this.id);
      this.blogObservable.subscribe(result => {
        this.blog = result;
      })
      console.log("segundo")

    }

  }

}



