import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seriados',
  templateUrl: './seriados.component.html',
  styleUrls: ['./seriados.component.css']
})
export class SeriadosComponent implements OnInit {

   quadrinhos: any[] = [];
   quadrinhosid: any[] = [];

   constructor(
     private EQS: EcleticoQuadrinhoService,
   ) {
     this.loadItems();
   }
  
   ngOnInit() {
   }
  
   
   loadItems() {
    
    this.quadrinhos=this.EQS.responseSerie;
    console.log(this.quadrinhos)

    }
   }
