import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EcleticoQuadrinhoService } from '../service/ecletico-quadrinho.service';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css']
})
export class PesquisaComponent implements OnInit {
  quadrinhos: any[] = [];
  id: string;
  blog: any[]=[];
  search:string="";
  constructor(private route: ActivatedRoute,
    private EQS: EcleticoQuadrinhoService,
    private router: Router) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.pesquisar(this.id);
  }
  abrir(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId
    this.router.navigate(['/ecleticos', quadrinhoId]);
  }
  abrirSeriados(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId
    this.router.navigate(['/seriados', quadrinhoId])
  }
  novaPesquisa(id:string){
    this.router.navigate(['/pesquisar', id]);
    this.pesquisar(id);
  }
  pesquisar(id: string) {
    this.blog=[];
    this.quadrinhos = [];
    for (var i in this.EQS.responseQuadrinho) {
      if (this.EQS.responseQuadrinho[i].quadrinho.nome.includes(id) || this.EQS.responseQuadrinho[i].quadrinho.descricao.includes(id)
        || this.EQS.responseQuadrinho[i].quadrinho.tags.includes(id)) {
        this.quadrinhos.push(this.EQS.responseQuadrinho[i])
      }
    }
    for( var i in this.EQS.responseBlog){
      if(this.EQS.responseBlog[i].blog.titulo.includes(id) || this.EQS.responseBlog[i].blog.subtitulo.includes(id)
      || this.EQS.responseBlog[i].blog.texto.includes(id)){
        this.blog.push(this.EQS.responseBlog[i]);
      }
    }
  }

}
