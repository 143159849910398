import { Component, OnInit } from "@angular/core";
import {
  Action,
  AngularFirestore,
  DocumentSnapshot,
} from "@angular/fire/firestore";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-edit-quadrinho",
  templateUrl: "./edit-quadrinho.component.html",
  styleUrls: ["./edit-quadrinho.component.css"],
})
export class EditQuadrinhoComponent implements OnInit {
  router: string;
  nameValue: string;
  placeValue: string;
  variavel: number = 0;
  //Data object for listing items
  quadrinhos: any;
  quadrinhosid: any[] = [];
  //Save first document in snapshot of items received
  firstInResponse: any = [];
  id: string;
  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.firestore
      .collection("quadrinho")
      .doc((this.id = this.route.snapshot.paramMap.get("id")))
      .get()
      .subscribe(
        (response) => {
          this.quadrinhosid = [];
          this.quadrinhos = response.data();
          console.log(this.quadrinhos);
          //Initialize values
          this.prev_strt_at = [];
          this.pagination_clicked_count = 0;
          this.disable_next = false;
          this.disable_prev = false;
        },
        (error) => {}
      );
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  alterar() {
    this.firestore.doc("quadrinho/" + this.id).update(this.quadrinhos[0]);
  }
}
