export const firebaseConfig = {
    fire: {
        apiKey: "AIzaSyAYilYH0vlT9H7WU4tiRX0KVyMreyiYInY",
        authDomain: "quadrinhos9.firebaseapp.com",
        databaseURL: "https://quadrinhos9.firebaseio.com",
        projectId: "quadrinhos9",
        storageBucket: "quadrinhos9.appspot.com",
        messagingSenderId: "990526984584",
        appId: "1:990526984584:web:7bd3627935973309f1aa3d",
        measurementId: "G-V0EKZS4Q0L"
    }
}