import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  search: string = "";
  constructor(private router:Router) { }

  ngOnInit() {
  }
  pesquisar(){
    this.router.navigate(['/pesquisar', this.search]);
  }

}
