import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-ecleticos',
  templateUrl: './ecleticos.component.html',
  styleUrls: ['./ecleticos.component.css']
})
export class EcleticosComponent {
  //Models for Input fields

  mostrar: string;



  //Data object for listing items
  quadrinhos: any[] = [];
  //Save first document in snapshot of items received


  constructor(
    private EQS: EcleticoQuadrinhoService,
    private router: Router,
  ) {

  }

  async ngOnInit() {
    await this.loadItems();

  }
  abrir(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId
    this.router.navigate(['/ecleticos', quadrinhoId]);
  }
  pesquisarTitulo(id) {
    this.quadrinhos=[];
    for (var i in this.EQS.responseQuadrinho) {
      if (this.EQS.responseQuadrinho[i].quadrinho.tipo == 'ecleticos') {
        if (this.EQS.responseQuadrinho[i].quadrinho.nome.toLowerCase().includes(id.toLowerCase()) || this.EQS.responseQuadrinho[i].quadrinho.descricao.toLowerCase().includes(id)
          || this.EQS.responseQuadrinho[i].quadrinho.tags.includes(id.toLowerCase())) {
          this.quadrinhos.push(this.EQS.responseQuadrinho[i])
        }
      }
    }

  }

   novoAntigo() {
    this.quadrinhos.sort((a: any, b: any) => {
      if (a.quadrinho.timestamp > b.quadrinho.timestamp) {
        return 1;
      } else if (a.quadrinho.timestamp < b.quadrinho.timestamp) {
        return -1;
      }
    });

  }
  antigoNovo() {
    this.quadrinhos.sort((a: any, b: any) => {
      if (a.quadrinho.timestamp < b.quadrinho.timestamp) {
        return 1;
      } else if (a.quadrinho.timestamp > b.quadrinho.timestamp) {
        return -1;
      }
    });
  }

  async loadItems() {
    this.quadrinhos = [];
    for (var i in this.EQS.responseQuadrinho) {
      if (this.EQS.responseQuadrinho[i].quadrinho.tipo == 'ecleticos') {
        this.quadrinhos.push({ quadrinho: this.EQS.responseQuadrinho[i].quadrinho, id: this.EQS.responseQuadrinho[i].id });

      }
    }
  }
}