import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Blog } from './blog';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class blogDoc  {
    blogDoc: AngularFirestoreDocument<Blog>;
   constructor(private afs: AngularFirestore) {
   }
   init(pagina:string){
    this.blogDoc = this.afs.doc<Blog>('blog/' + pagina);
    return this.blogDoc.valueChanges();
   }
}