import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { LocalStorageService, LocalStorage } from "angular-web-storage";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EcleticoQuadrinhoService {
  quadrinhos: any[] = [];
  blogs: any[] = [];
  carregou: boolean;
  cacheVeri: any;
  responseQuadrinho: any;
  responseSerie: any[] = [];

  quadrinhoAtual: any;
  quadrinhoAtualId: any;
  responseBlog: any;

  blogAtual: any;
  blogIdAtual: any;
  constructor(
    private local: LocalStorageService,
    private firestore: AngularFirestore
  ) {}
  tudo(res:any){
    this.blog(res);
    this.quadrinho(res);
    this.seriado(res);

  }

  quadrinho(cacheVeri: any) {
    if (this.local.get("quadrinhos") == undefined) {
      this.firestore
        .collection("quadrinho")
        .snapshotChanges()
        .subscribe((response) => {
          if (!response.length) {
            console.log("No Data Available");
            return false;
          }
          response.sort((a: any, b: any) => {
            if (
              a.payload.doc.data().timestamp > b.payload.doc.data().timestamp
            ) {
              return 1;
            } else if (
              a.payload.doc.data().timestamp < b.payload.doc.data().timestamp
            ) {
              return -1;
            }
          });
          this.quadrinhos = [];
          for (let item of response) {
            this.quadrinhos.push({
              quadrinho: item.payload.doc.data(),
              id: item.payload.doc.id,
            });
          }
          this.local.set("quadrinhostime", JSON.stringify(cacheVeri.quadrinho));
          this.local.set("quadrinhos", JSON.stringify(this.quadrinhos));
        });
      this.responseQuadrinho = JSON.parse(this.local.get("quadrinhos"));
    } else if (this.local.get("quadrinhos") != undefined) {
      if (
        parseInt(JSON.parse(this.local.get("quadrinhostime"))) <
        cacheVeri.quadrinho
      ) {
        this.firestore
          .collection("quadrinho")
          .snapshotChanges()
          .subscribe((response) => {
            if (!response.length) {
              console.log("No Data Available");
              return false;
            }
            response.sort((a: any, b: any) => {
              if (
                a.payload.doc.data().timestamp > b.payload.doc.data().timestamp
              ) {
                return 1;
              } else if (
                a.payload.doc.data().timestamp < b.payload.doc.data().timestamp
              ) {
                return -1;
              }
            });

            this.quadrinhos = [];
            for (let item of response) {
              this.quadrinhos.push({
                quadrinho: item.payload.doc.data(),
                id: item.payload.doc.id,
              });
            }
            this.local.set("quadrinhos", JSON.stringify(this.quadrinhos));
            this.local.set(
              "quadrinhostime",
              JSON.stringify(cacheVeri.quadrinho)
            );
          });
          console.log("elseIFquadrinhos");

      }
      console.log("elsequadrinhos");

      this.responseQuadrinho = JSON.parse(this.local.get("quadrinhos"));
    }
    console.log(this.responseQuadrinho);
  }

  seriado(cacheVeri: any) {
    if (this.local.get("seriado") == undefined) {
      this.firestore
        .collection("seriados")
        .snapshotChanges()
        .subscribe((response) => {
          if (!response.length) {
            console.log("No Data Available");
            return false;
          }
          this.responseSerie = [];
          for (let item of response) {
            this.responseSerie.push(item.payload.doc.data());
          }
          this.local.set("seriadotime", JSON.stringify(cacheVeri.seriado));
          this.local.set("seriado", JSON.stringify(this.responseSerie));
        });
      this.responseSerie = JSON.parse(this.local.get("seriado"));
      console.log("ifserie");
    } else if (this.local.get("seriado") != undefined) {
      if (
        parseInt(JSON.parse(this.local.get("seriadotime"))) < cacheVeri.seriado
      ) {
        this.firestore
          .collection("seriados")
          .snapshotChanges()
          .subscribe((response) => {
            if (!response.length) {
              console.log("No Data Available");
              return false;
            }

            this.responseSerie = [];
            for (let item of response) {
              this.responseSerie.push(item.payload.doc.data());
            }
            this.local.set("seriadotime", JSON.stringify(cacheVeri.seriado));
            this.local.set("seriado", JSON.stringify(this.responseSerie));
          });
        console.log("elseifserie");
      }
      console.log("elseSerie");

      this.responseSerie = JSON.parse(this.local.get("seriado"));
    }
  }

  blog(cacheVeri: any) {
    if (this.local.get("blogs") == undefined) {
      console.log("ifblog");
      this.firestore
        .collection("blog")
        .snapshotChanges()
        .subscribe((response) => {
          if (!response.length) {
            console.log("No Data Available");
            return false;
          }
          response.sort((a: any, b: any) => {
            if (
              a.payload.doc.data().timestamp > b.payload.doc.data().timestamp
            ) {
              return 1;
            } else if (
              a.payload.doc.data().timestamp < b.payload.doc.data().timestamp
            ) {
              return -1;
            }
          });
          this.blogs = [];
          for (let item of response) {
            this.blogs.push({
              blog: item.payload.doc.data(),
              id: item.payload.doc.id,
            });
          }
          this.local.set("blogstime", JSON.stringify(cacheVeri.blog));
          this.local.set("blogs", JSON.stringify(this.blogs));
        });
      this.blogs = JSON.parse(this.local.get("blogs"));
      console.log("IfBLog");
    } else if (this.local.get("blogs") != undefined) {
      if (parseInt(JSON.parse(this.local.get("blogstime"))) < cacheVeri.blog) {
        console.log("elseifblog");
        this.firestore
          .collection("blog")
          .snapshotChanges()
          .subscribe((response) => {
            if (!response.length) {
              console.log("No Data Available");
              return false;
            }
            response.sort((a: any, b: any) => {
              if (
                a.payload.doc.data().timestamp > b.payload.doc.data().timestamp
              ) {
                return 1;
              } else if (
                a.payload.doc.data().timestamp < b.payload.doc.data().timestamp
              ) {
                return -1;
              }
            });
            this.blogs = [];
            for (let item of response) {
              this.blogs.push({
                blog: item.payload.doc.data(),
                id: item.payload.doc.id,
              });
            }
            this.local.set("blogs", JSON.stringify(this.blogs));
            this.local.set("blogstime", JSON.stringify(cacheVeri.blog));
          });
        console.log("elseIfBLog");
      }

      this.blogs = JSON.parse(this.local.get("blogs"));
    }
    console.log("elseBLog");
  }
}
