import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { quadrinhoDoc } from 'src/app/service/quadrinhoDoc';
import { Observable, EMPTY } from 'rxjs';
import { Quadrinho } from 'src/app/service/quadrinho';
import { take } from 'rxjs/operators';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';


@Component({
  selector: 'app-seriados-detalhe',
  templateUrl: './seriados-detalhe.component.html',
  styleUrls: ['./seriados-detalhe.component.css']
})
export class SeriadosDetalheComponent {
  id: string;
  //Disable next and prev buttons
  next: boolean = true;
  prev: boolean = true;
  options: any = {
    title: 0,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      fullscreen: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: 0,
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    }
  };
  authResult: any;
  usuario: any;
  favorito: { url: any; id: string; tipo: string, nome: string };
  quadrinhoObservable: Observable<Quadrinho>;
  quadrinho;
  constructor(public quadrinhoDoc: quadrinhoDoc, private route: ActivatedRoute,
    private router: Router,
    private EQS: EcleticoQuadrinhoService
    ) {

  }
  abrir(id:string){
    this.router.navigate(['/seriados', id]);

  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      if (this.EQS.quadrinhoAtualId != undefined && this.EQS.quadrinhoAtualId==params.id) {
        this.quadrinho = this.EQS.quadrinhoAtual;
        console.log("AAA")
      } else {
      
        console.log("BBB")
        this.quadrinhoObservable = this.quadrinhoDoc.init(params.id);
        this.quadrinhoObservable.subscribe(result => {
          this.quadrinho = result;
        })
      }
     })

  }
  

}
