import { Component, OnInit } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';


@Component({
  selector: 'app-blog-header',
  templateUrl: './blog-header.component.html',
  styleUrls: ['./blog-header.component.css']
})
export class BlogHeaderComponent implements OnInit {

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  blogs: any[] = [];
  blogid: any[] = [];

  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  constructor(
    private firestore: AngularFirestore,
    private EQS: EcleticoQuadrinhoService,
    private router: Router
  ) {

  }

  async ngOnInit() {
   await this.loadItems();
  }

  abrir(blog: any, blogId: any) {
    this.EQS.blogAtual = blog;
    this.EQS.blogIdAtual = blogId
    this.router.navigate(['/blog', blogId]);
  }
  async loadItems() {
    this.blogs = [];
      for (var i in this.EQS.blogs) {
        this.blogs.push({ blog: this.EQS.blogs[i].blog, id: this.EQS.blogs[i].id });

      }
      this.novoAntigo();

}
  pesquisarTitulo(id){
    this.blogs=[];
    for (var i in this.EQS.blogs){
      if(this.EQS.blogs[i].blog.titulo.toLowerCase().includes(id.toLowerCase())|| this.EQS.blogs[i].blog.subtitulo.toLowerCase().includes(id.toLowerCase())){
        this.blogs.push({ blog: this.EQS.blogs[i].blog, id:this.EQS.blogs[i].id});
      }
    }
  }
  novoAntigo() { 
    this.blogs.sort((a: any, b: any) => {
      if (a.blog.timestamp < b.blog.timestamp) {
        return 1;
      } else if (a.blog.timestamp > b.blog.timestamp) {
        return -1;
      }
    });
  }
  antigoNovo() {
    this.blogs.sort((a: any, b: any) => {
      if (a.blog.timestamp > b.blog.timestamp) {
        return 1;
      } else if (a.blog.timestamp < b.blog.timestamp) {
        return -1;
      }
    });
  }
  




}
