import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adicionar',
  templateUrl: './adicionar.component.html',
  styleUrls: ['./adicionar.component.css']
})
export class AdicionarComponent implements OnInit {

  usuario: string ="";
  senha:string ="";
  logado:boolean = false;
  constructor() { }

  ngOnInit() {
  }
  entrar() {
    if (this.usuario == "duanealves" && this.senha == "senhadoduane") {
      this.logado = true;
    }
  }
}
