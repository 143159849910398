import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { EcleticoQuadrinhoService } from "../service/ecletico-quadrinho.service";

@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.css"],
})
export class TagComponent implements OnInit {
  id: string;
  nameValue: string;
  placeValue: string;
  botao: boolean = true;
  quadrinhos: any[] = [];
  blogs: any[] = [];
  quadrinhosid: any[] = [];
  blogVeri: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private EQS: EcleticoQuadrinhoService,
    private router: Router
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.carregar(this.id);
  }
  abrirEcle(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId;
    this.router.navigate(["/ecleticos", quadrinhoId]);
  }
  abrirSeria(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId;
    this.router.navigate(["/seriados", quadrinhoId]);
  }
  abrirBlog(blog: any, blogid: any) {
    this.EQS.blogIdAtual = blogid;
    this.EQS.blogAtual = blog;
    this.router.navigate(["/blog", blogid]);
  }

  carregar(id: string) {
    for (var i in this.EQS.responseQuadrinho) {
      if (this.EQS.responseQuadrinho[i].quadrinho.tags.includes(id)) {
        this.quadrinhos.push(this.EQS.responseQuadrinho[i]);
      }
    }

    for (var i in this.EQS.blogs) {
      if (this.EQS.blogs[i].blog.tags.includes(id)) {
        this.blogs.push(this.EQS.blogs[i]);
        this.blogVeri = true;
      }
    }
  }
}
