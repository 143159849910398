import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EcleticoQuadrinhoService } from 'src/app/service/ecletico-quadrinho.service';

@Component({
  selector: 'app-seriados-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.css']
})
export class SeriadosListaComponent implements OnInit {

  nameValue: string;
  placeValue: string;
  id: string;
  responseResult: any;
  num: string;

  //Data object for listing items
  quadrinhos: any[] = [];
  seriados: any;


  constructor(
    private route: ActivatedRoute,
    private EQS: EcleticoQuadrinhoService,
    private router: Router

  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadItems();
  }
  abrir(quadrinho: any, quadrinhoId: any) {
    this.EQS.quadrinhoAtual = quadrinho;
    this.EQS.quadrinhoAtualId = quadrinhoId
    this.router.navigate(['/seriados', quadrinhoId]);
  }
  loadItems() {
    for(var j in this.EQS.responseSerie){
      if(this.EQS.responseSerie[j].serie==this.id){
        this.seriados=this.EQS.responseSerie[j];
        console.log(this.seriados);
      }

    }
    this.quadrinhos = [];
    for(var i in this.EQS.responseQuadrinho){
      if(this.EQS.responseQuadrinho[i].quadrinho.tipo=='seriados'){
        if(this.EQS.responseQuadrinho[i].quadrinho.serie==this.id){
          this.quadrinhos.push({quadrinho:this.EQS.responseQuadrinho[i].quadrinho,id:this.EQS.responseQuadrinho[i].id});
        }
      }
    }
    console.log(this.quadrinhos);
  }
  novoAntigo() {
    this.quadrinhos.sort((a: any, b: any) => {
      if (a.quadrinho.capitulo > b.quadrinho.capitulo) {
        return 1;
      } else if (a.quadrinho.capitulo < b.quadrinho.capitulo) {
        return -1;
      }
    });
  }
  antigoNovo() {
    this.quadrinhos.sort((a: any, b: any) => {
      if (a.quadrinho.capitulo < b.quadrinho.capitulo) {
        return 1;
      } else if (a.quadrinho.capitulo > b.quadrinho.capitulo) {
        return -1;
      }
    });


  }


}
